@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Asap', 'Roboto', sans-serif;
  overflow-y: auto;
  background-color: #f6f6f6;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input, button {
  outline: none !important;
  background-color: inherit;
}

input:focus{
  border-color: #e35d5d;
}

button[type="submit"] {
  transition: 50ms;
  box-shadow: 5px 5px 4px #c4c4c4;
}

button[type="submit"]:hover {
  transition: 50ms;
  box-shadow: 8px 8px 8px #c4c4c4;
}

button[type="submit"]:active {
  transition: 50ms;
  box-shadow: 2px 2px 2px #c4c4c4;
}
